// Custom Theming for NG-ZORRO
// For more information: https://ng.ant.design/docs/customize-theme/en
@import '../node_modules/ng-zorro-antd/ng-zorro-antd.less';

// Override less variables here
// View all variables: https://github.com/NG-ZORRO/ng-zorro-antd/blob/master/components/style/themes/default.less
@versionColor: 1;
@colorNav:  if(@versionColor = 1, #737277,if( @versionColor = 2 #003366,#2494F2));

// -------- Colors -----------
@primary-color: #007bff;
@info-color: #17a2b8;
@success-color: #28a745;
@processing-color: #007bff;
@error-color: #dc3545;
@highlight-color: #dc3545;
@warning-color: #ffc107;

@normal-color: #d9d9d9;
@white: #fff;
@black: #000;

// Background color for `<body>`
@body-background: #fff;
// Base background color for most components
@component-background: #fff;

@font-family: system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', 'Noto Sans', 'Liberation Sans', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
@code-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;

@text-color: fade(@black, 85%);
@text-color-secondary: fade(@black, 45%);
@text-color-inverse: @white;
@icon-color: inherit;
@icon-color-hover: fade(@black, 75%);
@heading-color: fade(@black, 85%);
@text-color-dark: fade(@white, 85%);
@text-color-secondary-dark: fade(@white, 65%);
@text-selection-bg: @primary-color;
@font-variant-base: tabular-nums;
@font-feature-settings-base: 'tnum';

@font-size-base: 14px;
@font-size-lg: @font-size-base * 1.25;
@font-size-sm: @font-size-base * 0.875;

@heading-1-size: @font-size-base * 2.5;
@heading-2-size: @font-size-base * 2;
@heading-3-size: @font-size-base * 1.75;
@heading-4-size: @font-size-base * 1.5;
@heading-5-size: @font-size-base * 1.25;
@heading-6-size: @font-size-base;

@spacer: 1rem;
@headings-font-weight: 500;

// Typography
// ---
@typography-title-font-weight: @headings-font-weight;
@typography-title-margin-top: 1.2em;
@typography-title-margin-bottom: @spacer / 2;

// Variables should follow the `$prefix-component-state-property-size` formula for
// consistent naming. Ex: --gk-nav-link-disabled-color and --gk-modal-content-box-shadow-xs.
// Genomik
:root {
    --gk-font-family: @font-family;
    --gk-mono-font-family: @code-family;
    --gk-sidenav-base-width: 225px;
    --gk-sidenav-zindex: 999;
    --gk-topnav-base-height: 56px;
    --gk-topnav-background-color:  @colorNav;
    --gk-topnav-zindex: 1000;
}

// Boostrap
:root {
    --bs-blue: #0d6efd;
    --bs-indigo: #6610f2;
    --bs-purple: #6f42c1;
    --bs-pink: #d63384;
    --bs-red: #dc3545;
    --bs-orange: #fd7e14;
    --bs-yellow: #ffc107;
    --bs-green: #198754;
    --bs-teal: #20c997;
    --bs-cyan: #0dcaf0;
    --bs-black: #000;
    --bs-white: #fff;
    --bs-gray: #6c757d;
    --bs-gray-dark: #343a40;
    --bs-gray-100: #f8f9fa;
    --bs-gray-200: #e9ecef;
    --bs-gray-300: #dee2e6;
    --bs-gray-400: #ced4da;
    --bs-gray-500: #adb5bd;
    --bs-gray-600: #6c757d;
    --bs-gray-700: #495057;
    --bs-gray-800: #343a40;
    --bs-gray-900: #212529;
    --bs-primary: #0d6efd;
    --bs-secondary: #6c757d;
    --bs-success: #198754;
    --bs-info: #0dcaf0;
    --bs-warning: #ffc107;
    --bs-danger: #dc3545;
    --bs-light: #f8f9fa;
    --bs-dark: #212529;
    --bs-primary-rgb: 13, 110, 253;
    --bs-secondary-rgb: 108, 117, 125;
    --bs-success-rgb: 25, 135, 84;
    --bs-info-rgb: 13, 202, 240;
    --bs-warning-rgb: 255, 193, 7;
    --bs-danger-rgb: 220, 53, 69;
    --bs-light-rgb: 248, 249, 250;
    --bs-dark-rgb: 33, 37, 41;
    --bs-white-rgb: 255, 255, 255;
    --bs-black-rgb: 0, 0, 0;
    --bs-body-color-rgb: 33, 37, 41;
    --bs-body-bg-rgb: 255, 255, 255;
    --bs-font-sans-serif: var(--gk-font-family);
    --bs-font-monospace: var(--gk-mono-font-family);
    --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
    --bs-body-font-family: var(--bs-font-sans-serif);
    --bs-body-font-size: 1rem;
    --bs-body-font-weight: 400;
    --bs-body-line-height: 1.5;
    --bs-body-color: #212529;
    --bs-body-bg: #fff;
    --bs-border-width: 1px;
    --bs-border-style: solid;
    --bs-border-color: #dee2e6;
    --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
    --bs-border-radius: 0.375rem;
    --bs-border-radius-sm: 0.25rem;
    --bs-border-radius-lg: 0.5rem;
    --bs-border-radius-xl: 1rem;
    --bs-border-radius-2xl: 2rem;
    --bs-border-radius-pill: 50rem;
    --bs-link-color: #0d6efd;
    --bs-link-hover-color: #0a58ca;
    --bs-code-color: #d63384;
    --bs-highlight-bg: #fff3cd;
    --bs-backdrop-bg: #000;
    --bs-backdrop-opacity: 0.5;
    --bs-backdrop-zindex: 1050;
}
