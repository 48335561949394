// Default behavior for the sidenav layout
// The default positioning for the sidenav is a static position
#layoutSidenav {
    display: flex;
    // Wraps the .sb-sidenav element and sets the size
    #layoutSidenav_nav {
        flex-basis: var(--gk-sidenav-base-width);
        flex-shrink: 0;
        transition: transform 0.15s ease-in-out;
        z-index: var(--gk-sidenav-zindex);
        // Mobile first transform - by default the sidenav will be moved off-canvas
        transform: translateX(calc(var(--gk-sidenav-base-width) * -1));
    }
    // Wraps the content when using the sidenav layout
    #layoutSidenav_content {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-width: 0;
        flex-grow: 1;
        margin-left: calc(var(--gk-sidenav-base-width) * -1);
    }
}

// Default behavior for the static sidenav collapse
.sb-sidenav-toggled {
    #layoutSidenav {
        #layoutSidenav_nav {
            transform: translateX(0);
        }
        #layoutSidenav_content {
            &:before {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                // background: $black;
                z-index: var(--bs-backdrop-zindex);
                opacity: 0.5;
                transition: opacity 0.3s ease-in-out;
            }
        }
    }
}

@media only screen and (hover: none) and (pointer: coarse) {
    .sb-sidenav-toggled {
        #layoutSidenav {
            #layoutSidenav_content {
                &:before {
                    display: none;
                }
            }
        }
    }
}

// Responsive styling for the sidenav layout
@include media-breakpoint-up(lg) {
    #layoutSidenav {
        #layoutSidenav_nav {
            transform: translateX(0);
        }
        #layoutSidenav_content {
            margin-left: 0;
            transition: all 0.15s ease-in-out;
        }
    }
    // Behavior for the sidenav collapse on screens larger than the med breakpoint
    .sb-sidenav-toggled {
        #layoutSidenav {
            #layoutSidenav_nav {
                transform: translateX(calc(var(--gk-sidenav-base-width) * -1));
            }
            #layoutSidenav_content {
                margin-left: calc(var(--gk-sidenav-base-width) * -1);
                // Removes the sidenav overlay on screens larger than the med breakpoint
                &:before {
                    display: none;
                }
            }
        }
    }
}
