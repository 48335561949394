.sb-nav-fixed {
    .sb-topnav {
        @extend .fixed-top !optional;
        z-index: var(--gk-topnav-zindex);
    }
    #layoutSidenav {
        #layoutSidenav_nav {
            @extend .fixed-top !optional;
            width: var(--gk-sidenav-base-width);
            height: 100vh;
            z-index: var(--gk-sidenav-zindex);
            .sb-sidenav {
                padding-top: var(--gk-topnav-base-height);
                .sb-sidenav-menu {
                    overflow-y: auto;
                }
            }
        }
        #layoutSidenav_content {
            padding-left: var(--gk-sidenav-base-width);
            margin-top: var(--gk-topnav-base-height);
        }
    }
}
