// Color variables for the topnav
$topnav-primary-toggler-color: fade-out($white, 0.5);
$topnav-dark-toggler-color: fade-out($white, 0.5);
$topnav-light-toggler-color: $gray-900;

// Base styling for the topnav

.sb-topnav {
    padding-left: 0;
    height: var(--gk-topnav-base-height);
    z-index: var(--gk-topnav-zindex);
    .navbar-brand {
        width: var(--gk-sidenav-base-width);
        padding-left: $navbar-padding-x;
        padding-right: $navbar-padding-x;
        margin: 0;
    }
    &.navbar-primary {
        #sidebarToggle {
            color: $topnav-primary-toggler-color;
        }
    }
    &.navbar-dark {
        #sidebarToggle {
            color: $topnav-dark-toggler-color;
        }
    }
    &.navbar-light {
        #sidebarToggle {
            color: $topnav-light-toggler-color;
        }
    }
}
