#layoutAuthentication {
    display: flex;
    flex-direction: column;
    //min-height: 100vh;
    #layoutAuthentication_content {
        min-width: 0;
        flex-grow: 1;
    }
    #layoutAuthentication_footer {
        min-width: 0;
    }
}

.bg-primary-auth {
    background-color: #003366 !important;
    height: 100%;
}