//@import url('https://fonts.googleapis.com/css?family=Roboto');
// Global styling for this template
html,
body {
    height: 100%;
    font-family: 'Roboto'; // 'montserrat', Helvetica, sans-serif;
    background-color: #f8f8f8;
    color: #3c3d41;
    font-size: 12px;
}

// Set spacing on containers for page symmetry
.container,
.container-fluid {
    padding-left: $grid-gutter-width;
    padding-right: $grid-gutter-width;
}

.white {
    color: #fff !important;
}

.dataTables_paginate {
    float: right;
}

.dataTables_filter {
    float: right;
}

.slider-title {
    font-size: 34px;
}

.slider-subtitle {
    font-size: 22px;
    font-family: 'montserratlight', sans-serif;
    color: #ccc;
}

.c-title {
    font-size: 34px;
    font-family: 'montserratlight', sans-serif;
}

.n-list {
    margin-top: 30px;
}

.p-0-20 {
    padding: 0 20px;
}

.n-list {
    margin-top: 30px;
}

.n-list .item {
    margin: 10px 0px !important;
}

.n-list .br {
    border-right: solid 1px #ced1d1;
}

.n-list .row:nth-child(odd) {
    background-color: #eeeeee;
}

.bold-link-green {
    color: #09ab08;
    font-family: 'montserratbold', sans-serif;
    font-weight: bolder;
    text-decoration: none;
}

.bold-link-red {
    color: #fa0000;
    font-family: 'montserratbold', sans-serif;
    font-weight: bolder;
}

.link-green {
    color: #09ab08;
    font-family: 'montserratbold', sans-serif;
    text-decoration: none;
}

.link-red {
    color: #fa0000;
    font-family: 'montserratbold', sans-serif;
}

.n-list .row .item:first-child {
    font-size: 12px;
    //text-transform: uppercase;
    font-family: 'Roboto', sans-serif; //'montserratbold', sans-serif;
    //font-weight: bolder;
}

.block-flat,
.block-wizard {
    margin-bottom: 40px;
    padding: 20px 20px;
    background: #fff;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    border-left: 1px solid #efefef;
    border-right: 1px solid #efefef;
    border-bottom: 1px solid #e2e2e2;
    box-shadow: 0 1px 0 0 rgb(0 0 0 / 4%);
}

.ta-r {
    text-align: right;
}

.ta-l {
    text-align: left;
}

.navbar-default .navbar-brand {
    padding-left: 35px;
    text-align: left;
    width: 206px;
    color: #fff;
    background-color: rgba(0, 0, 0, 0);
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.2);
    font-weight: 300;
    font-size: 19px;
    line-height: 18px;
    height: auto;
}

.dropdown-toggle-ico {
    border-radius: 2px;
    -webkit-border-radius: 2px;
    padding: 8px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    width: 30px;
    box-shadow: 1px 1px 0 rgba(255, 255, 255, 0.2) inset;
    text-align: center;
    color: #fff !important;
}

.dropdown-toggle-no-ico::after {
    border-right: 0;
    border-bottom: 0;
    border-left: 0;
    // float: left;
}

.dropdown {
    color: #fff;
}

.min-ocultar {
    display: none;
}

@media screen and (max-width: 800px) {
    .divoculto {
        display: none;
    }
}

@media screen and (min-width: 800px) {
    .nav-flex-icons {
        display: none;
    }
}

@media screen and (max-width: 800px) {
    .min-ocultar {
        display: block;
    }
}

// @media screen and (max-width: 800px) {
//     .min-ocultar  {
//         display: block;
//     }
// }

.full-width{
    width: 100%;
}

.w100 {
    width: 100%;
    border-radius: 50%;
}

.mb1 {
    margin-bottom: 1%;
}

.mb2 {
    margin-bottom: 2%;
}

.mb5 {
    margin-bottom: 5%;
}

//Estilos de Tablas
.table td,
.table th {
    padding: 4px !important;
    vertical-align: middle !important;
}

.ttdgray {
    background: linear-gradient(to bottom, #eaeaea 0%, #979797 98%);
    color: #555;
    border-left: 0px solid #dadada;
    border-right: 0px solid #dadada;
    border-bottom: 0px solid #dadada;
    border-top: 0px solid #dadada;
    text-transform: uppercase;
    font-weight: bold;
}

.ttdblack {
    background: rgb(85, 85, 85);
    color: #555;
    border-left: 0px solid #dadada;
    border-right: 0px solid #dadada;
    border-bottom: 0px solid #dadada;
    border-top: 0px solid #dadada;
    text-transform: uppercase;
    font-weight: bold;
}

//estilos tablas
div .dataTables_scrollHeadInner {
    width: 100% !important;
}

.no-footer {
    width: 100% !important;
    margin-bottom: 0 !important;
}

.dataTables_scrollHeadInner2 thead {
    background-color: #033866;
    color: #fff;
    padding: 0px;
    margin-left: 0px;
    text-transform: uppercase;
}

.dataTables_scrollHeadInner thead {
    background: linear-gradient(to bottom, #697f9b 0%, #244771 98%);
    color: #ffffff;
    border-left: 0px solid #dadada;
    border-right: 0px solid #dadada;
    border-bottom: 0px solid #dadada;
    border-top: 0px solid #dadada;
    text-transform: uppercase;
    font-weight: bold;
    vertical-align: middle;
    padding: 0px;
    margin-left: 0px;
    text-transform: uppercase;
}

.ttdblue {
    background: linear-gradient(to bottom, #697f9b 0%, #244771 98%);
    color: #ffffff;
    border-left: 0px solid #dadada;
    border-right: 0px solid #dadada;
    border-bottom: 0px solid #dadada;
    border-top: 0px solid #dadada;
    text-transform: uppercase;
    font-weight: bold;
    vertical-align: middle;
    /*text-align:center*/
}

.ttdblue2 {
    background: linear-gradient(to bottom, #FFFFFF 0%, #428bca 98%);
    color: #000;
    border-left: 0px solid #DADADA;
    border-right: 0px solid #DADADA;
    border-bottom: 0px solid #DADADA;
    border-top: 0px solid #DADADA;
    text-transform: uppercase;
    font-weight: bold;
}

.ttdGRAY {
    background: #555;
    color: #ffffff;
    border-left: 0px solid #dadada;
    border-right: 0px solid #dadada;
    border-bottom: 0px solid #dadada;
    border-top: 0px solid #dadada;
    text-transform: uppercase;
    font-weight: bold;
    vertical-align: middle;
    /*text-align:center*/
}

.ColorIn {
    background-color: #fcf8e3;
    border-color: #fcf8e3;
}

.ColorOut {
    background-color: #f2dede;
    border-color: #f2dede;
}

//clase para alinear numeros
.numbers {
    text-align: right;
}


/* Iconos para DataTables */

.icon-danger {
    background-image: url(https://admin-genomik-ng-assets.s3.amazonaws.com/icons/danger.svg);
    background-repeat: no-repeat;
    background-position: center, center;
    background-size: contain;
    width: 30%;
    display: inline-block;
}

.icon-edit {
    background-image: url(https://admin-genomik-ng-assets.s3.amazonaws.com/icons/edit.svg);
    background-repeat: no-repeat;
    background-position: center, center;
    background-size: contain;
    width: 30%;
    display: inline-block;
}

.icon-edit:hover {
    width: 30%;
    cursor: pointer;
}

.icon-edit:active {
    width: 30%;
    cursor: pointer;
}

.icon-delete {
    background-image: url(https://admin-genomik-ng-assets.s3.amazonaws.com/icons/delete.svg);
    background-repeat: no-repeat;
    background-position: center, center;
    background-size: contain;
    width: 30%;
    display: inline-block;
}

//estilos pantalla delete-study
.borderless,
.border-null {
    padding: 5px;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 0px;
    border-left: 0px;
}

.bg-primary-especial {
    background-color: #dc3545 !important;
}

.bg-success {
    background-color: #60c060 !important;
}

.bg-error {
    background-color: #bd362f !important;
}

.bg-info {
    background-color: #2f96b4 !important;
}

.bg-warning {
    background-color: #f89406 !important;
}

.error {
    color: red;
    font-size: 14px;
    margin-bottom: 7px;
    margin-left: 15px;
}

.btn-default,
.btn-default:focus {
    background-color: #ffffff;
    border-color: #cccccc;
    color: #333333;
    background-image: linear-gradient(to bottom, #ffffff 60%, #f9f9f9 100%);
}

.btn-default:hover,
.btn-default:active {
    color: #333;
    background-color: #ebebeb;
    border-color: #adadad;
}

.cursor-pointer {
    cursor: pointer;
}

.txtlinkGreen {
    color: #09ab08;
    text-decoration: none;
    background-color: transparent;
    cursor: pointer !important;
    font-weight: bold;
}
.txtlinkCliente-false {
    color: #003366;
    text-decoration: none;
    background-color: transparent;
    cursor: pointer !important;
    font-weight: bold;
}

.txtlinkCliente-true {
    color: #09ab08;
    text-decoration: none;
    background-color: transparent;
    cursor: pointer !important;
    font-weight: bold;
}

.lnkgreen {
    color: #09ab08 !important;
}

.lnkgreen:hover {
    text-decoration: underline !important;
}

.txtlinka {
    color: #003366;
    text-decoration: none;
    background-color: transparent;
    cursor: pointer !important;
    font-weight: bold;
}

.txtlinka:hover {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
    cursor: pointer !important;
    font-weight: bold;
}

.ttdgray {
    background: linear-gradient(to bottom, #eaeaea 0%, #979797 98%);
    color: #000;
    border-left: 0px solid #dadada;
    border-right: 0px solid #dadada;
    border-bottom: 0px solid #dadada;
    border-top: 0px solid #dadada;
    text-transform: uppercase;
    font-weight: bold;
}

.ttdgraylight {
    background: linear-gradient(to bottom, #eaeaea 0%, #b2bac5 98%);
    color: #000;
    border-left: 0px solid #dadada;
    border-right: 0px solid #dadada;
    border-bottom: 0px solid #dadada;
    border-top: 0px solid #dadada;
    text-transform: uppercase;
    font-weight: bold;
}

.ttdgraydark {
    background-color: #555555;
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    font-weight: bold;
}

.i {
    font-style: italic;
}

.bold {
    font-weight: bold;
}

.well {
    min-height: 20px;
    padding: 19px;
    padding-top: 10px;
    margin-bottom: 20px;
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 5%);
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 5%);
    border-radius: 25px;
}

.btn-success {
    background-color: #60c060 !important;
}

.cdk-overlay-container {
    z-index: 1050;
}

.list-st-none {
    list-style: none;
    padding-left: 0px;
}

nz-select {
    font-size: 1rem !important;
    font-weight: 400 !important;
}

.border-none {
    border-top: none !important;
    border: none !important;
}

label {
    font-weight: 600;
    margin-bottom: 7px;
}

.dataTables_length {
    text-align: left;
}

.color-success {
    color: #60c060 !important;
}

.color-error {
    color: #bd362f !important;
}

.card {
    border: 0 solid rgba(0, 0, 0, .125);
}

//accordion custom GK
.card ng-star-inserted {
    margin-bottom: 5px;
    border: 0 solid;
}

.btn-accordion {
    padding: .75rem 1.25rem;
    font-size: 15px;
    color: #212529;
    background-color: white;
    text-align: left;
}

.btn-accordion:hover {
    color: #212529;
}

.btn-accordion[aria-expanded="true"] {
    color: white;
    background-color: $primary;
}

.btn-accordion>.fa-icon {
    margin-left: 1%;
    margin-right: .5%;
}

.btn-accordion[aria-expanded="true"]>.fa-icon>svg {
    transition: transform 200ms ease-in-out;
    margin-right: 10px;
    transform: rotate(90deg);
}

.btn-accordion[aria-expanded="false"]>.fa-icon>svg {
    transition: transform 200ms ease-in-out;
    margin-right: 10px;
    transform: rotate( 0deg);
}

.accordion>div>div {
    padding: 0px;
}

.form-control-nz {
    height: 43%;
}

.icheckbox_square-blue {
    display: inline-block;
    display: inline;
    vertical-align: middle;
    margin: 0;
    padding: 0;
    width: 22px;
    height: 22px;
    border: none;
    cursor: pointer;
}

.icheckbox_square-blue2 {
    display: inline-block;
    display: inline;
    vertical-align: middle;
    margin: 0;
    padding: 0;
    width: 20px;
    height: 20px;
    border: none;
    cursor: pointer;
}

// pantalla incidencia
.ck-editor__editable_inline {
    min-height: 100px;
}

.sinborde {
    border: 0;
}

.content-etiqueta p {
    margin: 0 10px 0 0;
}

.fondo-color {
    background-color: #f1f1f1;
    font-size: 18px;
}

.cursor-default {
    cursor: default !important;
}

.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-hover-color: #fff;
    --bs-btn-active-color: #fff
}

.modal-backdrop.modalcustom {
    z-index: 1000!important;
}

.cont-table {
    background-color: #fff !important;
    border-bottom: 1px solid #fff !important;
    border-top: 1px solid #fff !important;
    border-right: 1px solid #e4e2e2 !important;
    border-left: 1px solid #e4e2e2 !important;
    ;
    border-collapse: collapse !important;
    box-shadow: none !important;
}

.color-th {
    background-color: #fff;
    text-decoration: none;
    border-color: #fff;
    border: medium transparent
}

.modalzorro .ant-modal-content {
    border-radius: 16px 16px 16px 16px;
}

.modalbusqueda .ant-modal-header {
    background: #dc3545;
    border-radius: 16px 16px 0 0;
}

.accordion-button{
    font-size: 15px !important;
}

.accordion-header{
    margin-bottom: 0px !important;
}

.accordion-text{
    margin-left: 5px !important;
}

/*
@font-face {
 font-family: 'Roboto' !important;
 src: url('../assets/fonts/Roboto/Roboto-Regular.ttf') format('ttf') !important;
}
*/

.ql-editor {
    color: black;
}

.thinar {
    font-family: "Raleway", Helvetica, sans-serif !important;
    font-size: 25px!important;
}