// Adjust column spacing for symmetry
$spacer: 1rem;
$grid-gutter-width: $spacer * 1.5;

// Import Bootstrap
@import 'bootstrap/scss/bootstrap';

@import '../assets/css/stylesgk1.0.css';
//@import "../assets/css/dataTables.bootstrap5.min.css";

@import './theme-overrides/bootstrap';
@import './theme-overrides/ngb-bootstrap';
@import './theme-overrides/ng-zorro';

// Import Custom SCSS
@import './global';

// Layout
@import './layout';

// Navigation
@import './navigation';

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.ng-fa-icon{
    font-size: 15px !important;
}