

.table {
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
}

.table th,
.table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}

.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
}

.table tbody+tbody {
    border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
    padding: 0.3rem;
}

.table-bordered {
    border: 1px solid #dee2e6;
}

.table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6;
}

.table-bordered thead th,
.table-bordered thead td {
    border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody+tbody {
    border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: #f9f9f9; /*rgba(0, 0, 0, 0.05);*/
}

.table-hover tbody tr:hover {
    color: #212529;
    background-color: rgba(0, 0, 0, 0.05);/*rgba(0, 0, 0, 0.075);*/
}

.table-primary,
.table-primary>th,
.table-primary>td {
    background-color: #b8daff;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody+tbody {
    border-color: #7abaff;
}

.table-hover .table-primary:hover {
    background-color: #9fcdff;
}

.table-hover .table-primary:hover>td,
.table-hover .table-primary:hover>th {
    background-color: #9fcdff;
}

.table-secondary,
.table-secondary>th,
.table-secondary>td {
    background-color: #d6d8db;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody+tbody {
    border-color: #b3b7bb;
}

.table-hover .table-secondary:hover {
    background-color: #c8cbcf;
}

.table-hover .table-secondary:hover>td,
.table-hover .table-secondary:hover>th {
    background-color: #c8cbcf;
}

.table-success,
.table-success>th,
.table-success>td {
    background-color: #c3e6cb;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody+tbody {
    border-color: #8fd19e;
}

.table-hover .table-success:hover {
    background-color: #b1dfbb;
}

.table-hover .table-success:hover>td,
.table-hover .table-success:hover>th {
    background-color: #b1dfbb;
}

.table-info,
.table-info>th,
.table-info>td {
    background-color: #bee5eb;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody+tbody {
    border-color: #86cfda;
}

.table-hover .table-info:hover {
    background-color: #abdde5;
}

.table-hover .table-info:hover>td,
.table-hover .table-info:hover>th {
    background-color: #abdde5;
}

.table-warning,
.table-warning>th,
.table-warning>td {
    background-color: #ffeeba;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody+tbody {
    border-color: #ffdf7e;
}

.table-hover .table-warning:hover {
    background-color: #ffe8a1;
}

.table-hover .table-warning:hover>td,
.table-hover .table-warning:hover>th {
    background-color: #ffe8a1;
}

.table-danger,
.table-danger>th,
.table-danger>td {
    background-color: #f5c6cb;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody+tbody {
    border-color: #ed969e;
}

.table-hover .table-danger:hover {
    background-color: #f1b0b7;
}

.table-hover .table-danger:hover>td,
.table-hover .table-danger:hover>th {
    background-color: #f1b0b7;
}

.table-light,
.table-light>th,
.table-light>td {
    background-color: #fdfdfe;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody+tbody {
    border-color: #fbfcfc;
}

.table-hover .table-light:hover {
    background-color: #ececf6;
}

.table-hover .table-light:hover>td,
.table-hover .table-light:hover>th {
    background-color: #ececf6;
}

.table-dark,
.table-dark>th,
.table-dark>td {
    background-color: #c6c8ca;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody+tbody {
    border-color: #95999c;
}

.table-hover .table-dark:hover {
    background-color: #b9bbbe;
}

.table-hover .table-dark:hover>td,
.table-hover .table-dark:hover>th {
    background-color: #b9bbbe;
}

.table-active,
.table-active>th,
.table-active>td {
    background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
    background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover>td,
.table-hover .table-active:hover>th {
    background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
    color: #fff;
    background-color: #343a40;
    border-color: #454d55;
}

.table .thead-light th {
    color: #495057;
    background-color: #e9ecef;
    border-color: #dee2e6;
}

.table-dark {
    color: #fff;
    background-color: #343a40;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
    border-color: #454d55;
}

.table-dark.table-bordered {
    border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
    .table-responsive-sm {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
    .table-responsive-sm>.table-bordered {
        border: 0;
    }
}

@media (max-width: 767.98px) {
    .table-responsive-md {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
    .table-responsive-md>.table-bordered {
        border: 0;
    }
}

@media (max-width: 991.98px) {
    .table-responsive-lg {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
    .table-responsive-lg>.table-bordered {
        border: 0;
    }
}

@media (max-width: 1199.98px) {
    .table-responsive-xl {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
    .table-responsive-xl>.table-bordered {
        border: 0;
    }
}

.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.table-responsive>.table-bordered {
    border: 0;
}

.txtitgk{color :#FFF; font-family: Futura, Helvetica, sans-serif; font-size: 12px; font-weight:bold; text-decoration:none}

.block .header,
.widget-block .header,
.block-flat .header {
  padding: 10px;
  background-color: #FEFEFE;
  border-bottom: 1px solid #dadada;
}

.block .header,
.widget-block .header,
.block-flat .header {
  padding: 10px;
  background-color: #FEFEFE;
  border-bottom: 1px solid #dadada;
}

.block-flat .content {
    padding: 0px 0px 0px 0px;/*padding: 15px 3px 0 3px;*/
}
.block-flat .content.full-width {
    padding: 0;
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: -20px;
}
.block-flat .content h4 {
    margin-top: 20px;
            font-size: 16px;
}
.block-flat .content .title {
    margin: 5px 0 15px 0;
}
.block-flat.no-padding .content {
    padding: 0 !important;
}

.h3gk {
    font-family: Futura, Helvetica, sans-serif;
    font-weight: 500;
    margin-top: 3px;
    color: #56AA1C;
    font-size: 24px;
    line-height: 1.1;
}

.box {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .box div {
    width: 100px;
    height: 100px;
  }





/*


//estilos subrayado de title
// .breadcrumb {
//     display: -ms-flexbox;
//     display: flex;
//     -ms-flex-wrap: wrap;
//     flex-wrap: wrap;
//     padding: 0.75rem 1rem;
//     margin-bottom: 1rem;
//     list-style: none;
//     background-color: #e9ecef;
//     border-radius: 0.25rem;
// }

// .breadcrumb-item {
//     display: -ms-flexbox;
//     display: flex;
//     text-decoration:  none;
// }
// .breadcrumb-item a {
//     text-decoration: none ;
// }
// .breadcrumb-item a:hover {
//     text-decoration: underline ;
// }


// .breadcrumb-item+.breadcrumb-item {
//     padding-left: 0.5rem;
// }

// .breadcrumb-item+.breadcrumb-item::before {
//     display: inline-block;
//     padding-right: 0.5rem;
//     color: #6c757d;
//     content: "/";
// }

// .breadcrumb-item+.breadcrumb-item:hover::before {
//     text-decoration: underline;
// }

// .breadcrumb-item+.breadcrumb-item:hover::before {
//     text-decoration: line-through
// }

// .breadcrumb-item.active {
//     color: #6c757d;
// }

*/

.breadcrumb-item a:hover {
    color: #0b6dbe;
    text-decoration: underline
}

.breadcrumb-item a:not([href]):not([class]),a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none
}



/* estilos btn close modal */
.close{float:right;font-size:1.5rem;font-weight:700;line-height:1;color:#000;text-shadow:0 1px 0 #fff;opacity:.5}
.close:hover{color:#000;text-decoration:none}
.close:not(:disabled):not(.disabled):focus,.close:not(:disabled):not(.disabled):hover{opacity:.75}button.close{padding:0;background-color:transparent;border:0}
a.close.disabled{pointer-events:none}


.text-right {
    text-align: right !important;
}

.text-left {
    text-align: left !important;
}

.form-group {
    margin-bottom: 1rem;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff;
    background-color: #2494f2 !important;
}

.nav-pills .nav-link {
    border-radius: 0.25rem;
}

/* div.dataTables_length label{font-weight:normal;text-align:left;white-space:nowrap}

div.dataTables_wrapper div.dataTables_length select{
   width:auto;display:inline-block
}
   div.dataTables_wrapper div.dataTables_filter{text-align:right}
   div.dataTables_wrapper div.dataTables_filter label{font-weight:normal;white-space:nowrap;text-align:left}
   div.dataTables_wrapper div.dataTables_filter
   input{margin-left:.5em;display:inline-block;width:auto}
   div.dataTables_wrapper div.dataTables_info{padding-top:.85em}
   div.dataTables_wrapper div.dataTables_paginate{margin:0;white-space:nowrap;text-align:right}
   div.dataTables_wrapper div.dataTables_paginate ul.pagination{margin:2px 0;white-space:nowrap;justify-content:flex-end}div.dataTables_wrapper div.dataTables_processing{position:absolute;top:50%;left:50%;width:200px;margin-left:-100px;margin-top:-26px;text-align:center;padding:1em 0} */

.btn-link{
    text-decoration: none !important;
}
.btn-link:hover{
    text-decoration: underline !important;
}

nz-checkbox-group, label, .ant-checkbox-wrapper {

    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: #000000d9;
    font-weight: normal;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
    display: inline-flex;
    align-items: baseline;
    line-height: unset;
    cursor: pointer;

}

.table.dataTable{
    width: 100% !important;
}

.divider{
    width: 100%;
    height: 1px;
    display: block;
    background: #c7c7c7;
    margin: 30px 0;
}

.icheck{
    width: 20px !important;
    height: 20px !important;
}

.tbl-balanza-column-cuenta{
    display: flex;
}

.tbl-balanza-img{
    display: block;
    width: 16px;
    height: 16px;
    margin-top: 4px;
}

.pull-right{
    float: right;
}

.style7a {
    color: #003366;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    text-decoration: none;
}

.row{
    margin-bottom: 15px !important;
}

/*styled-table tabla con radius*/
.table-striped  {
    border-collapse: collapse;
    margin: 25px 0;
    min-width: 400px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    border-bottom: 10px #38577d solid !important;
    border-top: 10px #687e9a solid !important;
    border-radius: 14px;
}

.table-striped thead tr {
    color: #ffffff;
    text-align: left;
}

.table-striped  th,
.table-striped td {
    padding: 12px 15px;
}

.table-striped th {
    border: none;
}
.table-bordered>:not(caption)>* {
    border-width: 0 !important;
}
/*fin styled-table tabla con radius*/
